import { HTMLContent } from '@datacamp/le-shared-components';
import React from 'react';

type Props = {
  message: string;
};

const SqlQueryMessage: React.FC<Props> = ({ message }) => (
  <HTMLContent extraClass="sql-query-message" html={message} />
);

export default SqlQueryMessage;
