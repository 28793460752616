import { connect } from 'react-redux';

import ExerciseFooter from '../components/ExerciseFooter';
import * as selectors from '../redux/selectors';

const mapStateToProps = (state: any) => ({
  progress: selectors.selectUserProgress(state),
});

export default connect(mapStateToProps)(ExerciseFooter);
