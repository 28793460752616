import config from '../../config';

export type SaveScoreBody = {
  feedback?: string;
  score: number;
};

export type GeneratePredictionBody = {
  // should contain all the inputs necessary for the model
  // for example: code, errorMessage, language
  input: Record<string, unknown>;
  shouldStream: boolean;
  simulateStreamDelay?: number;
  trigger: {
    _tag: 'campusUser' | 'campusUserHint' | 'campusUserExplanation';
    exerciseId: number;
  };
  useCache?: boolean;
};

export const generatePrediction = async ({
  body,
  params,
}: {
  body: GeneratePredictionBody;
  params: { modelTag: string };
}): Promise<Response> =>
  fetch(
    `${config.urls.aiApi}/learn/v1/prediction/generate/${params.modelTag}`,
    {
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );

export const saveScore = async ({
  body,
  params,
}: {
  body: SaveScoreBody;
  params: { predictionId: number };
}): Promise<Response> =>
  fetch(
    `${config.urls.aiApi}/learn/v1/prediction/${params.predictionId}/score`,
    {
      body: JSON.stringify(body),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  );
