/* eslint-disable no-param-reassign */
// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';

import config from '../config';

export const scrollTo = (
  element: Element,
  to: number,
  duration: number,
): void => {
  const step = 10;
  if (duration <= 0 || !element) {
    return;
  }
  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * step;

  setTimeout(() => {
    element.scrollTop += perTick;
    if (element.scrollTop === to) {
      return;
    }
    scrollTo(element, to, duration - step);
  }, step);
};

export const autoScrollTo = (element: any): void => {
  if (config.isServerSideRendering) {
    return;
  }
  requestAnimationFrame(() => {
    const currentFocus = document.activeElement;
    if (!isNil(element)) {
      element.focus();
      if (!isNil(currentFocus)) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'focus' does not exist on type 'Element'.
        currentFocus.focus(); // focus back to the previous element
      }
    }
  });
};
