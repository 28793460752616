import { connect } from 'react-redux';

import NormalInstructions from '../../../components/Assignment/Instructions/NormalInstructions';
import * as selectors from '../../../redux/selectors';

const mapStateToProps = (state: any) => ({
  exercise: selectors.selectExercise(state).toJS(),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NormalInstructions);
