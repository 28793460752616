/** @jsx jsx */
import { Trophy } from '@datacamp/waffles/icon';
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import { css, jsx } from '@emotion/react';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useAnimationFrame, {
  easeInSine,
  interpolate,
} from '../helpers/useAnimationFrame';

type XPCounterProps = {
  xp: number;
};

const COUNTING_DURATION_IN_MS = 2000;

const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  marginRight: tokens.spacing.small,
  '@media (max-width: 1000px)': {
    display: 'none',
  },
} as const;

const iconWrapperStyle = {
  display: 'flex',
  verticalAlign: 'middle',
  marginRight: tokens.spacing.xsmall,
  color: tokens.colors.navy,
} as const;

const textStyle = {
  fontSize: tokens.fontSizes.large,
  fontWeight: tokens.fontWeights.bold,
};

const xpTextStyle = {
  marginLeft: tokens.spacing.xsmall,
  color: tokens.colors.pinkDarkText,
};

const xpCountingStyle = css`
  @keyframes wow {
    50% {
      font-size: 1.2rem;
    }
  }

  &.wow {
    animation: wow 0.3s 2;
  }
`;

const XPCounter: React.FC<XPCounterProps> = ({ xp }) => {
  const xpElRef = useRef<HTMLElement | null>(null);
  const xpRef = useRef<number>(xp);
  const { t } = useTranslation();

  const update = useMemo(() => {
    const visibleXP = xpRef.current;
    const xpEl = xpElRef.current;
    if (xpEl != null) {
      xpEl.classList.remove('wow');
    }
    return (elapsed: number) => {
      if (xpEl != null) {
        if (COUNTING_DURATION_IN_MS === elapsed) {
          xpEl.classList.add('wow');
        }
        const newXp = Math.round(
          interpolate({
            duration: COUNTING_DURATION_IN_MS,
            elapsed,
            end: xp,
            start: visibleXP,
            easingFunction: easeInSine,
          }),
        );
        xpRef.current = newXp;
        xpEl.innerHTML = `${newXp}`;
      }
    };
  }, [xp]);

  useAnimationFrame(update, COUNTING_DURATION_IN_MS);

  return (
    <div css={containerStyle}>
      <span css={iconWrapperStyle}>
        <Trophy />
      </span>
      <Text css={textStyle}>{t('XPCounter.dailyXp')}</Text>
      <Text css={[textStyle, xpTextStyle]}>
        <span ref={xpElRef} css={xpCountingStyle}>
          {xpRef.current}
        </span>
      </Text>
    </div>
  );
};
export default XPCounter;
