import { connect } from 'react-redux';

import ExamInstructions from '../../../components/Assignment/Instructions/ExamInstructions';
import config from '../../../config';
import * as selectors from '../../../redux/selectors';

const mapStateToProps = (state: any) => {
  const courseUrl = `${
    config.urls.datacamp
  }/courses/${selectors.selectCourseRef(state)}`;
  return {
    nbAttempts: selectors.selectNbAttempts(state),
    nextPath:
      selectors.selectNeighborsExercises(state).nextExercise || courseUrl,
  };
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ExamInstructions);
