export const COURSE_IDS_WITH_API_TOKENS: number[] = [
  32623, // Working with the OpenAI API
  33286, // ChatGPT Prompt Engineering for Developers
  33674, // Introduction to Embeddings with the OpenAI API
  34961, // Developing LLM Applications with LangChain
  35697, // Vector Databases for Embeddings with Pinecone
  36160, // Developing AI Systems with the OpenAI API
  36689, // AWS Bedrock API Test Course
  16719, // Streamlined Data Ingestion with Pandas
];

const API_TOKENS_TO_SCRUB = [
  [/[A-Za-z0-9_+=]{124,132}/g, '<YELP_API_TOKEN>'],
  [/[sawdxz]?[kjilm,]?[-_][a-zA-Z0-9]{40,56}/g, '<OPENAI_TOKEN>'],
  [/[hgyjnb]?[fdrgvc]?[_-][a-zA-Z]{26,42}/g, '<HUGGING_FACE_TOKEN>'],
  [
    /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gi,
    '<PINECONE_TOKEN>',
  ],
  [/AKIA[0-9A-Z]{16}/g, '<AWS_ACCESS_KEY>'],
  [/\b[A-Za-z0-9/+=]{40}\b/g, '<AWS_SECRET_KEY>'],
] as const;

export const scrubAPITokens = ({
  courseId,
  submission,
}: {
  courseId: number;
  submission: string;
}): string => {
  if (COURSE_IDS_WITH_API_TOKENS.includes(courseId)) {
    return API_TOKENS_TO_SCRUB.reduce(
      (acc, [regex, replacer]) => acc.replace(regex, replacer),
      submission,
    );
  }
  return submission;
};
