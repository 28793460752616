import { HTMLContent } from '@datacamp/le-shared-components';
import { Cross } from '@datacamp/waffles/icon';
import cx from 'classnames';
import React from 'react';

import DCLink from '../DCLink';

type Props = {
  closeErrorPane: (...args: any[]) => any;
  // TODO: ImmutablePropTypes.contains({ message: PropTypes.string, show: PropTypes.bool, })
  // import ImmutablePropTypes from 'react-immutable-proptypes';
  error?: any;
};

const SqlErrorPane: React.FC<Props> = ({ closeErrorPane, error }) => {
  const { message = '', show }: any = error ? error.toJS() : {};
  return (
    <div className={cx('sql-query-result-error')}>
      <div
        className={cx('sql-query-result-error__wrapper', {
          'sql-query-result-error__shrink': !show,
        })}
      >
        <HTMLContent
          mathJaxEnabled
          extraClass="sql-query-result-error__info"
          html={message}
        />
        <DCLink
          onClick={closeErrorPane}
          className="sql-query-result-error__close"
        >
          <Cross size="small" />
        </DCLink>
      </div>
    </div>
  );
};

export default SqlErrorPane;
