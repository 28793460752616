/* eslint-disable no-param-reassign */

type Data = any[] | any | null;

const isArray = (data: Data): boolean => Array.isArray(data);

const isObject = (data: Data): boolean =>
  typeof data === 'object' && !isArray(data) && data !== null;

const flatten = (data: Data): Data => {
  if (!data.attributes) {
    return data;
  }

  return {
    ...(data.id && { id: data.id }),
    ...data.attributes,
  };
};

export const normalize = (data: Data): Data => {
  if (isArray(data)) {
    return data.map((item: Data) => normalize(item));
  }

  if (isObject(data)) {
    const { data: child } = data;

    if (isArray(child)) {
      return child.map((item: Data) => normalize(item));
    }

    if (isObject(child)) {
      data = flatten({ ...child });
    }

    if (child === null) {
      return null;
    }

    if (child !== null) {
      data = flatten(data);
    }

    if (!data) {
      return null;
    }

    Object.keys(data).forEach((key: Data) => {
      data[key] = normalize(data[key]);
    });
  }

  return data;
};
