import { connect } from 'react-redux';

import MultipleChoiceInstructions from '../../../components/Assignment/Instructions/MultipleChoiceInstructions';
import * as actions from '../../../redux/actions';
import * as selectors from '../../../redux/selectors';

const mapStateToProps = (state: any) => ({
  exercise: selectors.selectExercise(state).toJS(),
  exerciseProgress: selectors.selectExerciseProgress(state).toJS(),
});

const mapDispatchToProps = (dispatch: any) => ({
  onSelectChoice: (selection: any) =>
    dispatch(actions.chooseAnswer({ choice: selection })),
  onSubmitChoice: (settings: any) => dispatch(actions.executeCode(settings)),
  setMceChoicesFocus: (focused: any) =>
    dispatch(actions.setMceChoicesFocus({ focused })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MultipleChoiceInstructions);
