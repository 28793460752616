import { z } from 'zod';

export const DataWarehouseSession = z.intersection(
  z.object({
    expireAt: z.coerce.date(),
    id: z.string(),
    userId: z.string(),
  }),
  z.union([
    z.object({
      dbName: z.string(),
      dbPassword: z.string(),
      dbRole: z.string(),
      dbUser: z.string(),
      status: z.union([z.literal('expired'), z.literal('ready')]),
    }),
    z.object({
      status: z.union([
        z.literal('creating'),
        z.literal('error'),
        z.literal('expired'),
      ]),
    }),
  ]),
);

type _DataWarehouseSession = z.infer<typeof DataWarehouseSession>;

export type DataWarehouseSession<
  Status extends _DataWarehouseSession['status'] = _DataWarehouseSession['status']
> = _DataWarehouseSession & {
  status: Status;
};
