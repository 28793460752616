import { connect } from 'react-redux';

import Screen from '../components/Screen';
import * as selectors from '../redux/selectors';

const mapStateToProps = (state: any) => ({
  ...selectors.selectChapterWindow(state).toJS(),
  courseId: selectors.selectCourseId(state),
  courseSlug: selectors.selectCourse(state).get('slug'),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Screen);
