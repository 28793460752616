import { DiscriminatedUnion } from '../../interfaces/DiscriminatedUnion';

export enum ContentAuthorizationAccess {
  DENIED = 'denied',
  GRANTED = 'granted',
  INDETERMINATE = 'indeterminate',
}

export const ContentAuthorizationErrorResult = {
  invalidAccessValue: (accessValue: unknown) =>
    ({
      type: 'invalid-access-value',
      access: accessValue,
    } as const),
  unexpectedStatus: (status: number) =>
    ({
      type: 'unexpected-status',
      status,
    } as const),
  unhandledError: (error: Error) =>
    ({
      type: 'unhandled-error',
      error,
    } as const),
};

export type ContentAuthorizationErrorResult = DiscriminatedUnion<
  typeof ContentAuthorizationErrorResult
>;

export type ContentAuthorizationDecisionSource = 'circuit-breaker' | 'policy';

export type ContentAuthorizationDenyReason =
  | 'insufficient-subscription'
  | 'archived-resource';

export const ContentAuthorizationResult = {
  grant: (decisionSource: ContentAuthorizationDecisionSource) =>
    ({
      access: ContentAuthorizationAccess.GRANTED,
      decisionSource,
    } as const),
  indeterminate: (decisionSource: ContentAuthorizationDecisionSource) =>
    ({
      access: ContentAuthorizationAccess.INDETERMINATE,
      decisionSource,
    } as const),
  grantWithFallback: () =>
    ({
      access: ContentAuthorizationAccess.GRANTED,
      decisionSource: 'grant-fallback',
    } as const),
  grantWithError: (errorResult: ContentAuthorizationErrorResult) =>
    ({
      access: ContentAuthorizationAccess.GRANTED,
      decisionSource: 'grant-with-error',
      errorResult,
    } as const),
  denyWithReason: (
    decisionSource: ContentAuthorizationDecisionSource,
    denyReason: ContentAuthorizationDenyReason,
  ) =>
    ({
      access: ContentAuthorizationAccess.DENIED,
      decisionSource,
      denyReason,
    } as const),
};

export type ContentAuthorizationResult = DiscriminatedUnion<
  typeof ContentAuthorizationResult
>;

function isValidAccess(access: string): access is ContentAuthorizationAccess {
  return Object.values(ContentAuthorizationAccess).includes(
    access as ContentAuthorizationAccess,
  );
}

function fetchContentAuthorization(
  baseUrl: string,
  serviceOrigin: string,
  resourceId: string,
): Promise<Response> {
  return fetch(`${baseUrl}/authorize/${resourceId}`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-service-origin': serviceOrigin,
    },
    method: 'GET',
  });
}

async function handleOkResponse(
  response: Response,
): Promise<ContentAuthorizationResult> {
  const data = await response.json();
  const casAccess = data.access;

  if (!isValidAccess(casAccess)) {
    return ContentAuthorizationResult.grantWithError(
      ContentAuthorizationErrorResult.invalidAccessValue(casAccess),
    );
  }

  switch (casAccess) {
    case ContentAuthorizationAccess.GRANTED:
      return ContentAuthorizationResult.grant(data.meta.decisionSource);
    case ContentAuthorizationAccess.DENIED:
      return ContentAuthorizationResult.denyWithReason(
        data.meta.decisionSource,
        data.meta.deny.reason,
      );
    default:
      return ContentAuthorizationResult.indeterminate(data.meta.decisionSource);
  }
}

function handleErrorResponse(response: Response): ContentAuthorizationResult {
  const isServerError = response.status >= 500;
  const isResourceNotFound = response.status === 404;
  const shouldFallbackToAccessGranted = isServerError || isResourceNotFound;

  if (shouldFallbackToAccessGranted) {
    return ContentAuthorizationResult.grantWithFallback();
  }

  return ContentAuthorizationResult.grantWithError(
    ContentAuthorizationErrorResult.unexpectedStatus(response.status),
  );
}

export type ContentAuthorizationClient = {
  authorize(resourceId: string): Promise<ContentAuthorizationResult>;
};
type CreateContentAuthorizationClientArgs = {
  baseUrl: string;
  serviceOrigin: string;
};
export const createContentAuthorizationClient = ({
  baseUrl,
  serviceOrigin,
}: CreateContentAuthorizationClientArgs): ContentAuthorizationClient => {
  return {
    async authorize(resourceId: string): Promise<ContentAuthorizationResult> {
      try {
        const response = await fetchContentAuthorization(
          baseUrl,
          serviceOrigin,
          resourceId,
        );

        if (response.ok) {
          return await handleOkResponse(response);
        }

        return handleErrorResponse(response);
      } catch (error) {
        return ContentAuthorizationResult.grantWithError(
          ContentAuthorizationErrorResult.unhandledError(error),
        );
      }
    },
  };
};
