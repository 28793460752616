import escape from 'lodash/escape';
import unescape from 'lodash/unescape';
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/transit-immutable-js` if i... Remove this comment to see the full error message
import transit from 'transit-immutable-js';

import { StateRecord } from '../redux/createStore';
import { BootStateRecord } from '../redux/reducers/boot';
import {
  PreFetchedDataStateRecord,
  PreFetchedRequestRecord,
} from '../redux/reducers/preFetchedData';

const transitWithRecords = transit.withRecords([
  PreFetchedRequestRecord,
  PreFetchedDataStateRecord,
  BootStateRecord,
  StateRecord,
]);

export const serialize = (state: any) =>
  escape(transitWithRecords.toJSON(state));

export const deserialize = (serializedState: any) =>
  transitWithRecords.fromJSON(unescape(serializedState));
