// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
import startsWith from 'lodash/startsWith';

import { SOURCE_TYPES } from '../../components/GraphicalOutput';

const hexToBase64 = (str: any) => {
  // Generate hex array from hex string
  const hexArray = str
    .replace(/\r|\n/g, '')
    .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
    .replace(/ +$/, '')
    .split(' ');

  const CHUNK_SIZE = 0x8000; // Arbitrary number
  let index = 0;
  const { length } = hexArray;
  let result = '';
  let slice;
  // Passing too many arguments into fromCharCode gives `Maximum call stack size exceeded`.
  // We divide the hex array into pieces and pass these.
  while (index < length) {
    slice = hexArray.slice(index, index + CHUNK_SIZE);
    result += String.fromCharCode.apply(null, slice);
    index += CHUNK_SIZE;
  }
  return window.btoa(result);
};

const createImageSrc = (payload: any) => {
  // Old SVGs (from Python) come in plain text
  if (payload.indexOf('<svg') > -1) {
    return `data:image/svg+xml,${escape(payload)}`;
  }
  // New SVGs (from Python) com in base64 - they always start with PD94
  if (startsWith(payload, 'PD94')) {
    return `data:image/svg+xml;base64,${payload}`;
  }
  // PNGs (from R) come in hex; need to convert to base 64
  return `data:image/png;base64,${hexToBase64(payload)}`;
};

const sourceFormatter = ({ isExpanded, multiplexerUrl, source }: any) => {
  if (source == null) {
    return {
      sourceType: null,
      sourceData: null,
    };
  }
  if (source.get('type') === 'iframe' && source.get('isRawContent')) {
    return {
      sourceType: SOURCE_TYPES.rawIframe,
      sourceData: source.get('content'),
    };
  }

  let sourceType;
  switch (source.get('type')) {
    case 'iframe':
      sourceType = SOURCE_TYPES.iframe;
      break;
    case 'pdf':
      sourceType = SOURCE_TYPES.pdf;
      break;
    default:
      sourceType = SOURCE_TYPES.img;
  }
  const baseUrl = source.get('isRelativeToMultiplexer') ? multiplexerUrl : '';
  const path =
    isExpanded && !isNil(source.get('expandedPath'))
      ? source.get('expandedPath')
      : source.get('path');
  let sourceData = `${baseUrl}${path}`;
  if (source.get('type') === 'plot') {
    sourceData = createImageSrc(sourceData);
  }
  return { sourceType, sourceData };
};

export default sourceFormatter;
