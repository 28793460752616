import config from '../config';

const targetOrigin = config.postMessageTargetOrigin;

export const notifyHostApplicationOfExerciseCompletion = (
  exerciseId: number,
): void => {
  window.parent.postMessage(
    {
      eventName: 'CAMPUS_EXERCISE_COMPLETED',
      exerciseId,
    },
    targetOrigin,
  );
};

export const notifyHostApplicationToShowContentOutline = (): void => {
  window.parent.postMessage(
    {
      eventName: 'CAMPUS_NAVIGATION_OUTLINE',
    },
    targetOrigin,
  );
};

export const notifyHostApplicationToShowPreviousExercise = (): void => {
  window.parent.postMessage(
    {
      eventName: 'CAMPUS_NAVIGATION_PREVIOUS',
    },
    targetOrigin,
  );
};

export const notifyHostApplicationToShowNextExercise = (): void => {
  window.parent.postMessage(
    {
      eventName: 'CAMPUS_NAVIGATION_NEXT',
    },
    targetOrigin,
  );
};
