import get from 'lodash/get';
// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import qs from 'qs';
// @ts-expect-error ts-migrate(7016) FIXME: Try `npm install @types/string-hash` if it exists ... Remove this comment to see the full error message
import hash from 'string-hash';

import config from '../../config';

import experiments from './experiments.json';

const trackABExperiment = (experiment: any, courseId: any, userId: any) => {
  if (config.isServerSideRendering) {
    return 'control';
  }

  // An experiment can be forced to a certain value thanks to the query parameter forced_experiments
  const queryParameters = qs.parse(document.location.search, {
    ignoreQueryPrefix: true,
  });
  const forcedExperiments = (queryParameters?.forced_experiments ??
    {}) as Record<string, string>;

  if (isString(forcedExperiments[experiment])) {
    return forcedExperiments[experiment];
  }

  // Only track for logged in users
  if (isNil(userId)) {
    return 'control';
  }

  // Ignore if the experiment is not declare in ./experiments.json
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (isNil(experiments[experiment])) {
    return 'control';
  }
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const experimentName = experiments[experiment].name;
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const treatmentRatio = get(experiments[experiment], 'treatmentRatio', {
    amount: 1,
    outOf: 2,
  });

  // Only track for given courses
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (!experiments[experiment].courseIds.includes(courseId)) {
    return 'control';
  }

  const hashedUser = hash(`${experimentName}${userId}`);
  const bucket =
    hashedUser % treatmentRatio.outOf < treatmentRatio.amount
      ? 'treatment'
      : 'control';

  return bucket;
};

export default trackABExperiment;
