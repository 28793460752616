import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import CodeExplanation from '../../components/CodeExplanation';
import { State } from '../../interfaces/State';
import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';

export type CodeExplanationState = {
  isClosed: boolean;
  uiTheme: string;
};

const mapStateToProps = (state: State): CodeExplanationState => {
  const isClosed = selectors.selectBottomPanelClosedState(state);

  return {
    isClosed,
    uiTheme: state.getIn(['settings', 'uiTheme']),
  };
};

export type CodeExplanationDispatch = {
  setClosed: (closed: boolean) => void;
};

const mapDispatchToProps = (dispatch: Dispatch): CodeExplanationDispatch => ({
  setClosed(closed: boolean) {
    dispatch(actions.setBottomPanelClosedState(closed));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CodeExplanation);
