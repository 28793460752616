import {
  RunCodeParams,
  SubmitCodeParams,
} from '@datacamp/learn-jsonrpc-mux-methods';

import { MultiplexerJsonRpcSessionManager } from './multiplexerJsonRpcSessionManager';

export const sendCommand = async ({
  commandConfig,
  muxSessionManager,
}: {
  commandConfig: {
    code?: string;
    command?: string;
    language?: string;
    sct?: string;
  };
  muxSessionManager: MultiplexerJsonRpcSessionManager;
}) => {
  if (muxSessionManager.client.remoteSession === null) {
    throw new Error('Remote session is not available');
  }
  switch (commandConfig.command) {
    case 'console': {
      const runParams = {
        language: commandConfig.language,
        code: commandConfig.code,
      };
      const runParamsResult = RunCodeParams.safeParse(runParams);
      if (!runParamsResult.success) {
        throw new Error('Invalid runParams');
      }
      const result = await muxSessionManager.client.remoteSession.runCode(
        runParamsResult.data,
      );
      if (result.type === 'backend-error') {
        throw new Error(result.error);
      }
      return [
        {
          type: result.type,
          payload: result.output,
        },
      ];
    }
    case 'submit': {
      const submitParams = {
        language: commandConfig.language,
        code: commandConfig.code,
        sct: commandConfig.sct,
      };
      const submitParamsResult = SubmitCodeParams.safeParse(submitParams);
      if (!submitParamsResult.success) {
        throw new Error('Invalid submitParams');
      }
      const result = await muxSessionManager.client.remoteSession.submitCode(
        submitParamsResult.data,
      );
      if (result.type === 'backend-error') {
        throw new Error(result.error);
      }
      return [
        {
          type: result.runResult.type,
          payload: result.runResult.output,
        },
        {
          type: 'sct' as const,
          payload: result.sctResult,
        },
      ];
    }
    default:
      throw new Error('Unsupported method');
  }
};
