import includes from 'lodash/includes';

export const isTabOrBulletConsoleEx = (exerciseType: any) =>
  includes(['BulletConsoleExercise', 'TabConsoleExercise'], exerciseType);

export const resetProgress = (exercise: any) =>
  exercise.update('subexercises', (subexercises: any) =>
    subexercises.map((subEx: any, index: any) =>
      subEx
        .set('unlocked', index === 0)
        .set('active', index === 0)
        .remove('completed'),
    ),
  );
