import includes from 'lodash/includes';
import result from 'lodash/result';

import { USER_STATUS } from '../redux/selectors/user';

export const isPaidUser = (user: any): boolean =>
  user.status === USER_STATUS.LOGIN &&
  (user.settings.has_active_subscription || user.settings.admin);

export const isFreeUser = (user: any): boolean =>
  user.status === USER_STATUS.LOGIN && !isPaidUser(user);

export const isUserNotRegistered = (user: any): boolean =>
  user.status === USER_STATUS.UNAUTHORIZED;

export const isUserLoggedIn = (user: any): boolean =>
  user.status === USER_STATUS.LOGIN;

export const isUserWithCourseAccess = (user: any, course: any): boolean =>
  // @ts-expect-error Argument of type 'unknown' is not assignable...
  includes(result(user, 'settings.course_accesses'), course.id);

export const getModalHooked = (
  user: any,
  restrictedFn: any,
  showModal: any,
): any => {
  switch (user.status) {
    case USER_STATUS.UNAUTHORIZED:
    case USER_STATUS.ERROR:
      return showModal;
    case USER_STATUS.PENDING:
    case USER_STATUS.NOT_INITIATE:
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    default:
      return restrictedFn;
  }
};
