// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
import qs from 'qs';

import config from '../config';

export const getUrlParamsFromVideoExercise = (precedingVideoExercise: any) => {
  return {
    projectorUrl: config.urls.projector,
    projectorKey:
      precedingVideoExercise == null
        ? undefined
        : precedingVideoExercise.projector_key,
    mp4Link:
      precedingVideoExercise == null
        ? undefined
        : precedingVideoExercise.video_link,
    hlsLink:
      precedingVideoExercise == null
        ? undefined
        : precedingVideoExercise.video_hls,
  };
};

export const getVideoUrl = ({
  hlsLink,
  mp4Link,
  projectorKey,
  projectorUrl,
}: {
  hlsLink: string;
  mp4Link: string;
  projectorKey: string;
  projectorUrl: string;
}): string => {
  const queryParams: Record<string, string> = {
    auto_play: 'pause',
  };

  if (!isNil(projectorKey)) {
    queryParams.projector_key = projectorKey;
  }

  if (!isNil(hlsLink)) {
    queryParams.video_hls = hlsLink;
  } else if (!isNil(mp4Link)) {
    queryParams.video_link = mp4Link;
  }

  return `${projectorUrl}/?${qs.stringify(queryParams)}`;
};
