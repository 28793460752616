import { formatDistanceStrict } from 'date-fns';

import { getDateFnsLocale } from '../i18n';

export const formatDistanceLocalized = (
  lastActiveDate: Date,
  now: Date,
  language: string,
): string => {
  const locale = getDateFnsLocale(language);

  return formatDistanceStrict(lastActiveDate, now, {
    locale,
    addSuffix: true,
  });
};
