import formatISO from 'date-fns/formatISO';
import parse from 'date-fns/parse';
import qs from 'qs';
import React from 'react';
import Helmet from 'react-helmet';

const prodThumbnailUrl =
  'https://campus.datacamp.com/public/assets/images/var/thumbnail.png';

const formatUploadDate = (chapterLastUpdatedOn: string): string => {
  try {
    const chapterLastUpdatedOnFormat = 'dd/MM/yyyy';
    const date = parse(
      chapterLastUpdatedOn,
      chapterLastUpdatedOnFormat,
      new Date(),
    );
    return formatISO(date);
  } catch (_) {
    return formatISO(new Date());
  }
};

type GetEmbedUrlArgs = {
  projectorKey?: string;
  projectorUrl: string;
  videoHls?: string;
};
const getEmbedUrl = ({
  projectorKey,
  projectorUrl,
  videoHls,
}: GetEmbedUrlArgs): string => {
  let queryParams = {};
  if (projectorKey != null) {
    queryParams = { projector_key: projectorKey };
  } else if (videoHls != null) {
    queryParams = { video_hls: videoHls };
  }
  return `${projectorUrl}/?${qs.stringify(queryParams)}`;
};

type GetNameArgs = {
  chapterTitle: string;
  exerciseTitle: string;
};
const getName = ({ chapterTitle, exerciseTitle }: GetNameArgs): string => {
  return `${chapterTitle} - ${exerciseTitle}`;
};

type GetVideoObjectArgs = {
  chapterDescription: string;
  chapterLastUpdatedOn: string;
  chapterTitle: string;
  exerciseTitle: string;
  projectorKey?: string;
  projectorUrl: string;
  videoHls?: string;
};
const getVideoObject = ({
  chapterDescription,
  chapterLastUpdatedOn,
  chapterTitle,
  exerciseTitle,
  projectorKey,
  projectorUrl,
  videoHls,
}: GetVideoObjectArgs): string => {
  const uploadDate = formatUploadDate(chapterLastUpdatedOn);
  const embedUrl = getEmbedUrl({ projectorKey, projectorUrl, videoHls });
  const name = getName({ chapterTitle, exerciseTitle });
  const videoObject = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name,
    description: chapterDescription,
    thumbnailUrl: [prodThumbnailUrl],
    uploadDate,
    embedUrl,
  };
  return JSON.stringify(videoObject);
};

type Props = {
  chapterDescription: string;
  chapterLastUpdatedOn: string;
  chapterTitle: string;
  exerciseTitle: string;
  projectorKey?: string;
  projectorUrl: string;
  videoHls?: string;
};

const VideoExerciseHead: React.FC<Props> = ({
  chapterDescription,
  chapterLastUpdatedOn,
  chapterTitle,
  exerciseTitle,
  projectorKey,
  projectorUrl,
  videoHls,
}) => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {getVideoObject({
          exerciseTitle,
          chapterTitle,
          chapterDescription,
          chapterLastUpdatedOn,
          projectorKey,
          projectorUrl,
          videoHls,
        })}
      </script>
    </Helmet>
  );
};

export default VideoExerciseHead;
