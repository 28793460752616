/* eslint-disable filenames/match-exported */
import { State } from '../../interfaces/State';
import { IEpicSubmitCodeAction, SubmitCodeSettings } from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import { keyIn } from '../immutable';

export type PartialSubmitCodeSettings = Partial<SubmitCodeSettings>;
export class BaseExerciseService {
  prepareSubmit(
    state: State,
    action: IEpicSubmitCodeAction,
  ): PartialSubmitCodeSettings {
    const exercise = selectors.selectExercise(state);
    const commandConfig = exercise
      .filter(
        keyIn('id', 'pre_exercise_code', 'solution', 'type', 'sct', 'language'),
      )
      .toJS();
    commandConfig.pec = commandConfig.pre_exercise_code;
    const dimension = selectors
      .selectGraphicalTabs(state)
      .getIn(['plot', 'dimension'])
      .toJS();

    const terminalCols = selectors
      .selectConsoleTabs(state)
      .getIn(['console', 'dimension', 'cols']);

    const config = {
      ...commandConfig,
      ...action.settings, // settings from the action will overwrite settings from the state.
      ...dimension,
      terminalCols,
    };

    if (config.command === 'console' && exercise.get('language') === 'sql') {
      config.command = 'run';
    }

    if (config.command === 'expand') {
      config.width = action.width;
      config.height = action.height;
    }

    return config;
  }
}

const ExerciseService = new BaseExerciseService();
export default ExerciseService;
