import { useCallback, useEffect, useRef } from 'react';

export const linear = (x: number): number => x;
export const easeInSine = (x: number): number => {
  return 1 - Math.cos((x * Math.PI) / 2);
};

export const interpolate = ({
  duration,
  elapsed,
  end,
  start = 0,
  easingFunction = linear,
}: {
  duration: number;
  easingFunction?: (progressPercentage: number) => number;
  elapsed: number;
  end: number;
  start?: number;
}): number => {
  return start + (end - start) * easingFunction(elapsed / duration);
};

/**
 * Hook to simplify smooth animations
 *
 * The hook resets if one of its arguments is updated.
 *
 * The last callback invocation will have msElapsed = durationInMs.
 * The callback needs to trigger a rerender if necessary
 * (when the animation doesn't use dom refs) (e.g. by using setState).
 * If the duration is nullish this is a noop.
 */
export default function useAnimationFrame(
  callbackInAnimationFrame: (msElapsed: number) => void,
  durationInMs?: number,
): void {
  const msElapsed = useRef(0);
  const execute = useCallback(
    () => callbackInAnimationFrame(msElapsed.current),
    [callbackInAnimationFrame],
  );

  useEffect(() => {
    let animationFrame: number;
    let timerStop: number;
    let start: number;

    const onFrame = (): void => {
      msElapsed.current = Date.now() - start;
      execute();
      animationFrame = requestAnimationFrame(onFrame);
    };

    const onStart = (): void => {
      timerStop = setTimeout(() => {
        cancelAnimationFrame(animationFrame);
        msElapsed.current = durationInMs || 0;
        execute();
      }, durationInMs);

      start = Date.now();
      animationFrame = requestAnimationFrame(onFrame);
    };

    if (durationInMs != null) {
      onStart();
    }

    return () => {
      clearTimeout(timerStop);
      cancelAnimationFrame(animationFrame);
    };
  }, [durationInMs, execute]);
}
