import React from 'react';

import Slides from '../../components/Slides';
import { useSelector } from '../../interfaces/State';
import { selectConsoleTabs } from '../../redux/selectors';

import { useSlides } from './hooks/useSlides';

const TabSlides: React.FC = () => {
  const { isClosed, setClosed, slides_link, uiTheme } = useSlides();

  const isActive = useSelector(
    (state) => selectConsoleTabs(state).toJS().slides.props.active,
  );

  return isActive ? (
    <Slides
      uiThemes={uiTheme}
      isClosed={isClosed}
      slides_link={slides_link}
      setClosed={setClosed}
    />
  ) : null;
};

export default TabSlides;
