import { connect } from 'react-redux';

import TableHandler from '../components/Sql/TableHandler';
import * as actions from '../redux/actions';
import * as selectors from '../redux/selectors';

const mapStateToProps = (key: any) => (state: any) => ({
  ...selectors.selectExercise(state).toJS(),
  ...selectors.selectCurrentSubExercise(state).toJS(),
  tab: selectors.selectConsoleSqlTabs(state).getIn([key, 'props']),
  sqlOptions: selectors.selectSqlOptions(state).toJS(),
  isClosed: selectors.selectBottomPanelClosedState(state),
  uiTheme: state.getIn(['settings', 'uiTheme']),
});

const mapDispatchToProps = (dispatch: any) => ({
  refreshTable: (settings: any) => dispatch(actions.executeCode(settings)),
  closeErrorPane: () => dispatch(actions.toggleSqlError(false)),
  onSubmitCode: (settings: any) => dispatch(actions.executeCode(settings)),
  setClosed: (closed: any) =>
    dispatch(actions.setBottomPanelClosedState(closed)),
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (key: any) =>
  connect(mapStateToProps(key), mapDispatchToProps)(TableHandler);
