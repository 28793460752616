import get from 'lodash/get';

import { USER_STATUS } from '../redux/selectors/user';

import { sendTagManagerEvent } from './sendTagManagerEvent';
import { technologyById } from './technology';

const getCurrentCourse = (state: any) => state.course;

const isAuthenticatedUser = (state: any) =>
  state.user.status === USER_STATUS.LOGIN;

const exerciseIsCompleted = (exercise: any) =>
  get(exercise, 'user.completed.completed', false);

const getCurrentChapterProgress = (state: any) => {
  const { exercises } = state;
  // This is the progress of the current chapter before the current session started
  const previousProgress = exercises.progress;

  // Return a list of booleans, defining whether an exercise at least completed
  // in "exercises.progress" or "exercises.current"
  // "exercises.progress" only exist for authenticated users
  return exercises.all.map(
    (exercise: any, index: any) =>
      exerciseIsCompleted(exercise) ||
      (isAuthenticatedUser(state) &&
        previousProgress != null &&
        previousProgress[index].completed),
  );
};

const currentChapterIsCompleted = (state: any) =>
  getCurrentChapterProgress(state).every((exIsCompleted: any) => exIsCompleted);

export const courseWasPreviouslyStarted = (state: any) =>
  get(getCurrentCourse(state), 'progress.started', false);

const emptyProgressOnCurrentChapter = (state: any) =>
  getCurrentChapterProgress(state).every(
    (exIsCompleted: any) => !exIsCompleted,
  ); // If every exercise in the current chapter is not completed

export const chapterIsNewlyStarted = (prevState: any, newState: any) =>
  emptyProgressOnCurrentChapter(prevState) &&
  !emptyProgressOnCurrentChapter(newState);

export const chapterIsNewlyCompleted = (prevState: any, newState: any) =>
  !currentChapterIsCompleted(prevState) && currentChapterIsCompleted(newState);

export const trackChapterStarted = (chapterId: any) => {
  sendTagManagerEvent({ event: 'start_chapter', content_id: chapterId });
};
const trackChapterCompleted = (chapterId: any) => {
  sendTagManagerEvent({ event: 'complete_chapter', content_id: chapterId });
};

// If the user is starting a chapter AND they have no existing
// completed exercises in the course, then we infer that they are
// starting the course for the first time
export const courseIsNewlyStarted = (prevState: any, newState: any) => {
  if (!chapterIsNewlyStarted(prevState, newState)) {
    return false;
  }

  const isAnonymousUser = newState.course.progress == null;
  if (isAnonymousUser) {
    return false;
  }

  const userChapters = newState.course.progress.user_chapters || [];
  const totalNumberOfCompletedExercises = userChapters.reduce(
    (total: number, user_chapter: any) => {
      return total + user_chapter.nb_completed_exercises;
    },
    0,
  );
  return userChapters.length >= 1 && totalNumberOfCompletedExercises === 0;
};

type CourseInformation = {
  courseId: number;
  courseXp: number;
  technology: string;
  time_needed_in_hours: number;
  title: string;
  topic_id: string;
};

type CourseEvent = 'start_course' | 'complete_course';

const trackCourseEvent = (
  event: CourseEvent,
  {
    courseId,
    courseXp,
    technology,
    time_needed_in_hours,
    title,
    topic_id,
  }: CourseInformation,
): void => {
  sendTagManagerEvent({
    event,
    content_id: courseId,
    technology,
    title,
    topic_id,
    time_needed_in_hours,
    xp: courseXp,
  });
};

export const trackCourseStarted = (courseInfo: CourseInformation): void => {
  trackCourseEvent('start_course', courseInfo);
};

export const trackCourseCompleted = (courseInfo: CourseInformation): void => {
  trackCourseEvent('complete_course', courseInfo);
};

export const trackFirstChapterCompleted = (
  chapterId: any,
  technology: string,
  is_free_course: boolean,
): void => {
  sendTagManagerEvent({
    event: 'first_chapter_completed',
    technology,
    content_id: chapterId,
    is_free_course,
    app_src: 'campus-web',
  });
};

const trackProgressGtm = (prevStateJS: any, newStateJS: any) => {
  const {
    id: courseId,
    technology_id,
    time_needed_in_hours,
    title,
    topic_id,
    xp,
  } = newStateJS.course;
  const technology =
    technologyById[technology_id as keyof typeof technologyById];

  const currentChapterId = newStateJS.chapter.current.id;

  if (chapterIsNewlyStarted(prevStateJS, newStateJS)) {
    trackChapterStarted(currentChapterId);
  } else if (chapterIsNewlyCompleted(prevStateJS, newStateJS)) {
    trackChapterCompleted(currentChapterId);
  }

  if (courseIsNewlyStarted(prevStateJS, newStateJS)) {
    trackCourseStarted({
      courseId,
      title,
      technology,
      topic_id,
      time_needed_in_hours,
      courseXp: xp,
    });
  }
};

export default trackProgressGtm;
