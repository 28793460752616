import { connect } from 'react-redux';

import Shortcuts from '../components/Assignment/Shortcuts';
import * as actions from '../redux/actions';
import * as selectors from '../redux/selectors';

const mapStateToProps = (state: any) => ({
  shouldCloseShortcutFrame: selectors.selectShouldCloseShortcutFrame(state),
  shortcutsUsage: selectors.selectShortcutsUsage(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  onClose: () => dispatch(actions.closeShortcutFrame()),

  onShowShortcutsModal: () =>
    dispatch(
      actions.showModal({ modal: selectors.MODAL_TYPE.KEYBOARD_SHORTCUTS }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Shortcuts);
