import React from 'react';

type Props = {
  activeKey?: string;
  className?: string;
  customComponentClass?: string;
};

type State = any;

class Tabs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeKey: this.props.activeKey,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (
      'activeKey' in nextProps &&
      nextProps.activeKey !== this.state.activeKey
    ) {
      this.setState({
        activeKey: nextProps.activeKey,
      });
    }
  }

  renderTabs(activeKey: any) {
    return React.Children.map(this.props.children, (child) => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'key' does not exist on type 'ReactNode'.
      const { key } = child;
      // @ts-expect-error ts-migrate(2769) FIXME: Type 'undefined' is not assignable to type 'ReactE... Remove this comment to see the full error message
      return React.cloneElement(child, {
        active: activeKey === key,
        key,
      });
    });
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className={this.props.customComponentClass || 'content--tab-body'}>
          {this.renderTabs(this.state.activeKey)}
        </div>
      </div>
    );
  }
}

export default Tabs;
