/* eslint-disable filenames/match-exported */
import each from 'lodash/each';
import merge from 'lodash/merge';
import set from 'lodash/set';

import { getTreatment } from '../featureFlags';
import { sendTagManagerEvent } from '../sendTagManagerEvent';

import contentExperiments from './content.json';

function transformOverrides(content: any) {
  const overrides = {};

  each(content, (exercisesByTreatmentAndId, splitName) => {
    each(exercisesByTreatmentAndId, (exercisesById, treatment) => {
      each(exercisesById, (exercise, id) => {
        set(overrides, [id, splitName, treatment], exercise);
      });
    });
  });

  return overrides;
}

const contentExperimentOverrides = transformOverrides(contentExperiments);
export default contentExperimentOverrides;

export function getOverridesForExercise(exerciseId: string | undefined) {
  const exerciseOverrides = {};
  if (exerciseId === undefined) return exerciseOverrides;

  each(
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    contentExperimentOverrides[exerciseId],
    (exercisesByTreatment, splitName) => {
      const treatment = getTreatment(splitName);
      if (treatment === 'control') return;
      sendTagManagerEvent({
        bucket: treatment,
        event: 'bucket_experiment',
        experiment_name: splitName,
      });
      merge(exerciseOverrides, exercisesByTreatment[treatment]);
    },
  );

  return exerciseOverrides;
}
