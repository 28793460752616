import {
  Actions,
  ExerciseClient,
  exercisePropTypes,
} from '@datacamp/exercise-contract';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import keys from 'lodash/keys';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

export default class ExerciseContract extends PureComponent {
  static propTypes = {
    ...exercisePropTypes,
    type: PropTypes.string.isRequired,
    isCurrent: PropTypes.bool.isRequired,
    onSubmitted: PropTypes.func,
    onMultiplexerStatusUpdated: PropTypes.func,
    onXpUpdated: PropTypes.func,
    onNext: PropTypes.func,
    children: PropTypes.func.isRequired,
  };

  exerciseClient: any;

  state = {
    isReady: false,
  };

  constructor(props: any) {
    super(props);
    this.exerciseClient = null;
  }

  componentWillReceiveProps(nextProps: any) {
    if (isNull(this.exerciseClient)) return;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isCurrent' does not exist on type 'Reado... Remove this comment to see the full error message
    if (!this.props.isCurrent && nextProps.isCurrent) {
      this.bindEventListeners();
    }

    if (this.hasNewExerciseProps(nextProps) && this.state.isReady) {
      this.setExerciseProps(nextProps);
    }

    if (!nextProps.isCurrent && this.state.isReady) {
      this.exerciseClient.removeAllListeners();
      this.setState({
        isReady: false,
      });
    }
  }

  componentWillUnmount() {
    this.removeExerciseClient();
  }

  setExerciseProps = (props: any) => {
    this.exerciseClient.setExerciseProps(this.getExerciseProps(props));
  };

  getExerciseProps = (props: any) => pick(props, keys(exercisePropTypes));

  hasNewExerciseProps = (nextProps: any) =>
    !isEqual(
      this.getExerciseProps(nextProps),
      this.getExerciseProps(this.props),
    );

  bindEventListeners = () => {
    if (isNull(this.exerciseClient)) return;

    this.exerciseClient.removeAllListeners();

    this.exerciseClient.on(Actions.CLIENT_READY, () => {
      this.setExerciseProps(this.props);
    });

    this.exerciseClient.on(Actions.EXERCISE_READY, () => {
      this.setState({
        isReady: true,
      });
    });
    // @ts-expect-error ts-migrate(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
    this.exerciseClient.on(Actions.SET_EXERCISE_STATUS, (...args) =>
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onMultiplexerStatusUpdated' does not exi... Remove this comment to see the full error message
      this.props.onMultiplexerStatusUpdated(...args),
    );
    // @ts-expect-error ts-migrate(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
    this.exerciseClient.on(Actions.EXERCISE_FINISHED, (...args) =>
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onNext' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.onNext(...args),
    );
    this.exerciseClient.on(
      Actions.EXERCISE_SUBMITTED,
      ({ correct, id, message, submission }: any) =>
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onSubmitted' does not exist on type 'Rea... Remove this comment to see the full error message
        this.props.onSubmitted({ id, correct, message, code: submission }),
    );
    // @ts-expect-error ts-migrate(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
    this.exerciseClient.on(Actions.SET_EXERCISE_XP, (...args) =>
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onXpUpdated' does not exist on type 'Rea... Remove this comment to see the full error message
      this.props.onXpUpdated(...args),
    );
  };

  removeExerciseClient() {
    if (!isNull(this.exerciseClient)) {
      this.exerciseClient.unsubscribe();
      this.exerciseClient.removeAllListeners();
      this.exerciseClient = null;
    }
  }

  setExerciseClient = (contentWindow: any) => {
    this.removeExerciseClient();
    this.exerciseClient = new ExerciseClient({
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      channelName: this.props.type,
      postElement: contentWindow,
      listenElement: window,
    });
    this.bindEventListeners();
  };

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isCurrent' does not exist on type 'Reado... Remove this comment to see the full error message
    const { children, isCurrent } = this.props;
    const { isReady } = this.state;
    // @ts-expect-error ts-migrate(2349) FIXME: No constituent of type 'string | number | boolean ... Remove this comment to see the full error message
    return children({
      isCurrent,
      isReady,
      setExerciseClient: this.setExerciseClient,
    });
  }
}
