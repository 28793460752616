import { ErrorBoundary } from '@datacamp/le-shared-components';
import raven from 'raven-js';
import React, { PureComponent, Suspense } from 'react';

import DCSpinner from '../DCSpinner';

import localExercises from './localExercises';

type Props = {
  isCurrent: boolean;
  setExerciseClient: (...args: any[]) => any;
  type: string;
};

class LocalStandaloneExercise extends PureComponent<Props> {
  componentDidMount() {
    this.props.setExerciseClient(window);
  }

  render() {
    const { isCurrent, type } = this.props;
    const Exercise = localExercises(type);
    if (isCurrent) {
      return (
        <ErrorBoundary onErrorReported={raven.captureException}>
          <Suspense fallback={<DCSpinner />}>
            <Exercise />
          </Suspense>
        </ErrorBoundary>
      );
    }
    return null;
  }
}

export default LocalStandaloneExercise;
