/* eslint-disable filenames/match-exported */
import * as selectors from '../../redux/selectors';

import ExerciseService from './base';
import ComposedExerciseService from './ComposedExercise';
import MarkdownExerciseService from './MarkdownExerciseService';

const getService = (type: any) => {
  switch (type) {
    case 'MarkdownExercise':
      return MarkdownExerciseService;
    case 'TabExercise':
    case 'BulletExercise':
    case 'TabConsoleExercise':
    case 'BulletConsoleExercise':
      return ComposedExerciseService;
    default:
      return ExerciseService;
  }
};

export const getServiceFromState = (state: any) =>
  getService(selectors.selectExercise(state).get('type'));
export default getService;
