import { connect } from 'react-redux';

import CourseOutlineOutsideButtons from '../components/CourseOutline/outsideButtons';
import * as actions from '../redux/actions';
import * as selectors from '../redux/selectors';

const mapStateToProps = (state: any) => ({
  course: selectors.selectCourse(state).toJS(),
  exerciseType: selectors.selectExercise(state).get('type'),
});

const mapDispatchToProps = (dispatch: any) => ({
  resetProgress: (settings: any) =>
    dispatch(actions.midResetCourseProgress(settings)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseOutlineOutsideButtons);
