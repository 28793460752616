import { Map as hashMap } from 'immutable';
import { connect } from 'react-redux';

import RDoc from '../../components/RDoc/RDoc';
import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';

const mapStateToProps = (state: any) => ({
  ...selectors
    .selectEditorTabs(state)
    .getIn(['rdoc', 'props'], hashMap())
    .toJS(),
});

const mapDispatchToProps = (dispatch: any) => ({
  sendCode: (code: any) =>
    dispatch(actions.executeCode({ code, command: 'console', echo: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RDoc);
