import {
  notificationDecoders,
  responseDecoders,
} from '@datacamp/learn-jsonrpc-mux-methods';
import {
  ISessionStatus,
  IStartOptions,
  JsonRpcErrorResponse,
  JsonRpcSession,
  RemoteSession,
} from '@datacamp/multiplexer-client-jsonrpc';
import { z } from 'zod';

import config from '../../config';

export type BackendError = {
  error: JsonRpcErrorResponse['error'];
  type: 'backend-error';
};

export const User = z.object({
  authentication_token: z.string(),
  email: z.string(),
});
export type User = z.infer<typeof User>;

export class MultiplexerJsonRpcSessionManager {
  public sessionId: string | null = null;

  #status: ISessionStatus = {
    status: 'none',
  };

  #statusSubscribers: Array<(status: ISessionStatus) => void> = [];

  private set status(newStatus: ISessionStatus) {
    if (newStatus === this.status) {
      return;
    }
    this.#status = newStatus;
    this.#statusSubscribers.forEach((statusSubscriber) =>
      statusSubscriber(newStatus),
    );
  }

  private get status(): ISessionStatus {
    return this.#status;
  }

  public readonly client: JsonRpcSession;

  constructor({
    startOptions,
    user,
  }: {
    startOptions: IStartOptions;
    user: {
      authentication_token: string;
      email: string;
    };
  }) {
    this.client = new JsonRpcSession({
      multiplexerServicesUrl: config.urls.multiplexer,
      multiplexerSessionsUrl: config.urls.multiplexer,
      user,
      responseDecoders,
      notificationDecoders,
      startOptions,
      sendFakeRequestsToKeepAlive: false,
    });
  }

  public async start(startOptions: IStartOptions): Promise<void> {
    const startResult = await this.client.start(startOptions);
    if (startResult == null) {
      return;
    }

    this.client.status.subscribe((status) => {
      this.status = status;
    });

    const { sessionId } = startResult;
    this.sessionId = sessionId;
    this.status = { status: 'ready' };
  }

  public subscribeToStatus(cb: (status: ISessionStatus) => void): void {
    this.#statusSubscribers.push(cb);
  }

  public stop(): void {
    this.client.closeConnection();
    this.#statusSubscribers = [];
  }

  public getRemoteSession(): RemoteSession | null {
    return this.client.remoteSession;
  }
}
