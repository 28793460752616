import get from 'lodash/get';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import Console from '../../components/Console';
import * as actions from '../../redux/actions';
import * as selectors from '../../redux/selectors';
import { BACKEND_STATUS } from '../../redux/selectors/backendSession';

const shortcutKeys = ['h'];

const mapStateToProps = (state: any, ownProps: any) => {
  const isClosed = selectors.selectBottomPanelClosedState(state);

  const multiplexerUrl = selectors.selectMultiplexerUrl(state);
  const shellProxy = selectors.selectShellProxy(state);
  let socketUrl = '';
  if (!isEmpty(multiplexerUrl) && !isEmpty(shellProxy)) {
    socketUrl = (multiplexerUrl + shellProxy).replace(/^.*:\/\//, 'wss://');
  }

  return {
    isClosed,
    language: selectors.selectLanguage(state),
    socketUrl,
    isSessionBroken:
      BACKEND_STATUS.BROKEN.code ===
      selectors.selectBackendSessionStatusCode(state),
    resizableParent: get(ownProps, 'glContainer.parent.parent'),
    lastError: selectors.selectBackendErrors(state).last() || '',
    shouldFocusOnOpen: includes(
      ['ConsoleExercise', 'TabConsoleExercise', 'BulletConsoleExercise'],
      selectors.selectExercise(state).get('type'),
    ),
    shortcutKeys,
    isExCompleted: selectors.selectIsExCompleted(state),
    uiTheme: state.getIn(['settings', 'uiTheme']),
    shouldFocus: !selectors.selectQueryNoFocus(state),
    deactivateToggleButton: selectors.selectEditorTabs(state).isEmpty(),
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  onError: () =>
    dispatch(
      actions.updateBackendStatus({
        status: BACKEND_STATUS.BROKEN.code,
        message: BACKEND_STATUS.BROKEN.text,
      }),
    ),

  setClosed(closed: any) {
    dispatch(actions.setBottomPanelClosedState(closed));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Console);
