import React from 'react';

type Props = {
  active?: boolean;
  children?: React.ReactNode;
  unmountDeactivated?: boolean;
};

const TabPane: React.FC<Props> = ({
  active,
  children,
  unmountDeactivated = false,
}) => {
  const style = {
    display: active ? 'inline' : 'none',
  };
  if (!active && unmountDeactivated) {
    return null;
  }
  return <div style={style}>{children}</div>;
};

export default TabPane;
