import Client from '@datacamp/prometheus-aggregator-client';

import config from '../config';

let client: Client | undefined;

const prometheusClient = (): Client => {
  if (client == null) {
    client = new Client({
      serverRoot: config.urls.prometheusAggregator,
      interval: 30000,
    });
  }
  return client;
};

export default prometheusClient;
