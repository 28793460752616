import config from '../config';

import ApiClient from './ApiClient';

const createClient = (path: any) => {
  let client: ApiClient | null = null;
  return () => {
    if (client === null) {
      client = new ApiClient(path);
    }
    return client;
  };
};

export const getMainAppApiClient = createClient(`${config.urls.datacamp}/api`);
export const getCampusApiClient = createClient(`${config.urls.campusApi}/api`);
export const getTeachClient = createClient(`${config.urls.teach}/api`);
export const getImbClient = createClient(config.urls.imb);
