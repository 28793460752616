import { useDispatch, useSelector } from '../../../interfaces/State';
import * as actions from '../../../redux/actions';
import {
  selectBottomPanelClosedState,
  selectChapter,
  selectSettings,
} from '../../../redux/selectors';

type Output = {
  isClosed: boolean;
  setClosed: (closed: boolean) => void;
  slides_link: string;
  uiTheme: 'LIGHT' | 'DARK';
};

export const useSlides = (): Output => {
  const dispatch = useDispatch();

  const slides_link = useSelector((state) =>
    selectChapter(state).get('slides_link'),
  );

  const isClosed = useSelector((state) => selectBottomPanelClosedState(state));

  const uiTheme = useSelector((state) => selectSettings(state).get('uiTheme'));

  const setClosed = (closed: boolean): void => {
    dispatch(actions.setBottomPanelClosedState(closed));
  };

  return {
    isClosed,
    setClosed,
    slides_link,
    uiTheme,
  };
};
