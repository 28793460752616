type GetGlobalProgressStateOutput = {
  chapter: {
    completed: boolean;
  };
  course: {
    completed: boolean;
  };
  exercise: {
    completed: boolean;
    totalXp?: number | undefined;
    xp?: number | undefined;
  };
};

const progressShape = {
  chapter: {
    completed: false,
  },
  course: {
    completed: false,
  },
  exercise: {
    completed: false,
  },
};

export const getGlobalProgressState = (
  currentExercise: any,
  exercises: any,
  exercisesProgress: any,
  courseProgress: any,
  chapterId: any,
): GetGlobalProgressStateOutput => {
  if (!(currentExercise && exercises)) {
    return progressShape;
  }
  const progress = progressShape;
  let chapterProgress = exercises.map(
    (ex: any) => ex.getIn(['user', 'completed', 'completed']) || false,
  );
  if (exercisesProgress) {
    chapterProgress = chapterProgress.zipWith(
      (complete: any, exProgress: any) =>
        complete || exProgress.get('completed') || false,
      exercisesProgress,
    );
  }
  progress.exercise.completed =
    currentExercise.getIn(['user', 'completed', 'completed']) || false;
  progress.chapter.completed = !chapterProgress.contains(false);
  progress.course.completed =
    (courseProgress && courseProgress.get('completed')) || false;

  const userChapters = courseProgress && courseProgress.get('user_chapters');
  if (
    !progress.course.completed &&
    progress.exercise.completed &&
    progress.chapter.completed &&
    userChapters
  ) {
    const userChaptersCompletion = userChapters.map((ch: any) => {
      if (ch.get('chapter_id') === chapterId) {
        return progress.chapter.completed;
      }
      return ch.get('completed') || false;
    });
    progress.course.completed = !userChaptersCompletion.contains(false);
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'totalXp' does not exist on type '{ compl... Remove this comment to see the full error message
  progress.exercise.totalXp = currentExercise.get('xp', 0);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'xp' does not exist on type '{ completed:... Remove this comment to see the full error message
  progress.exercise.xp = currentExercise.getIn(
    ['user', 'currentXp'],
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'totalXp' does not exist on type '{ compl... Remove this comment to see the full error message
    progress.exercise.totalXp,
  );
  if (exercisesProgress) {
    const exProgress = exercisesProgress.find(
      (ex: any) => currentExercise.get('id') === ex.exercise_id,
    );
    const exXpProgress = (exProgress && exProgress.get('xp')) || 0;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'xp' does not exist on type '{ completed:... Remove this comment to see the full error message
    if (exXpProgress > progress.exercise.xp) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'xp' does not exist on type '{ completed:... Remove this comment to see the full error message
      progress.exercise.xp = exXpProgress;
    }
  }
  return progress;
};
