import { CategoryPages } from '../redux/middlewares/boot/getCategoryPagesAsPromise';

import { Technology } from './technology';
import { Topic } from './topic';

export const getDescriptionPageParentBreadcrumb = ({
  categoryPages,
  technology,
  topic,
}: {
  categoryPages: CategoryPages;
  technology?: Technology | null;
  topic?: Topic | null;
}) => {
  const technologyMatch = categoryPages?.filter((page) => {
    return technology && page?.facet?.technology_array?.includes(technology);
  })?.[0];

  const topicMatch = categoryPages?.filter((page) => {
    return topic && page?.facet?.topic_array?.includes(topic);
  })?.[0];

  const name =
    technologyMatch?.facet?.technology_array?.[0] ||
    topicMatch?.facet?.topic_array?.[0] ||
    technology ||
    topic ||
    '';

  const categoryPageTechnologyPath =
    technologyMatch?.slug && `/category/${technologyMatch?.slug}`;
  const categoryPageTopicPath =
    topicMatch?.slug && `/category/${topicMatch?.slug}`;

  const coursesAllPath = technology && `/courses-all?technology=${technology}`;

  const link =
    categoryPageTechnologyPath || categoryPageTopicPath || coursesAllPath || '';

  if (!name || !link) {
    return null;
  }

  return {
    link,
    name,
  };
};
