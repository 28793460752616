/* eslint-disable no-param-reassign */
import { ArrowLeft, ArrowRight } from '@datacamp/waffles/icon';
import first from 'lodash/first';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import once from 'lodash/once';
import React from 'react';
import { render } from 'react-dom';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs/Rx';

const tabsPanes: any = []; // keep a reference of every tabs to be able to dispose it
const moveStep = 2;
const moveInterval = 5;

const getTabArrow = (direction: any, tabs: any) => {
  const tabArrow = document.createElement('span');

  const icon = direction === 'right' ? <ArrowRight /> : <ArrowLeft />;

  render(icon, tabArrow);

  tabArrow.className = 'scroll-tab-icon tab-scroll-legacy';
  tabArrow.style[direction] = direction === 'right' ? '31px' : '0px';
  const onmouseenter$ = Rx.Observable.fromEvent(tabArrow, 'mouseenter');
  const onmouseleave$ = Rx.Observable.fromEvent(tabArrow, 'mouseleave');
  onmouseenter$
    .concatMapTo(Rx.Observable.interval(moveInterval).takeUntil(onmouseleave$))
    .subscribe(() => {
      const { scrollLeft } = tabs;
      if (direction === 'left') {
        const newLeft = scrollLeft - moveStep;
        tabs.scrollLeft = newLeft >= 0 ? newLeft : 0;
      } else {
        const maxScrollLeft = tabs.scrollWidth - tabs.clientWidth;
        const newLeft = scrollLeft + moveStep;
        tabs.scrollLeft = newLeft >= maxScrollLeft ? maxScrollLeft : newLeft;
      }
    });
  return tabArrow;
};

const updateScroll = () =>
  forEach(tabsPanes, (tabs) => tabs.onscroll && tabs.onscroll());

export const disposeScrolls = () => {
  forEach(tabsPanes, (tabs) => {
    tabs.onscroll = null;
  });
};

const getActiveTab = (el: any) => el.querySelector('.lm_tab.lm_active');

const scrollSmoothly = (tabs: any, to: any) => {
  tabs.scrollLeft = to;
  // // to laggy, should check why
  // if (subscriberToMove) subscriberToMove.unsubscribe();
  // const steps = _.range(tabs.scrollLeft, to, tabs.scrollLeft < to ? moveStep : -moveStep).concat(to);
  // subscriberToMove = Rx.Observable
  //   .interval(moveInterval)
  //   .map(i => steps[i])
  //   .take(steps.length)
  //   .subscribe((scroll) => {
  //     tabs.scrollLeft = scroll;
  //   }, () => {}, updateIcons);
};

const goToActivetab = (el: any, tabs: any) => {
  const activeTab = getActiveTab(el);
  if (!activeTab) {
    return null;
  }
  const optimalScroll =
    activeTab.offsetLeft + activeTab.clientWidth / 2 - tabs.clientWidth / 2;
  // check if the optimal scroll is in the window that the user sees
  // if (optimalScroll >= tabs.scrollLeft && optimalScroll <= (tabs.scrollLeft + tabs.clientWidth)) return activeTab;
  const maxScrollLeft = tabs.scrollWidth - tabs.clientWidth;
  if (optimalScroll > maxScrollLeft) {
    scrollSmoothly(tabs, maxScrollLeft);
  } else {
    scrollSmoothly(tabs, optimalScroll > 0 ? optimalScroll : 0);
  }
  return activeTab;
};

const onWindowResize = once(() => {
  Rx.Observable.fromEvent(window, 'resize')
    .debounceTime(200)
    .subscribe(() => {
      updateScroll();
    });
});

const tabScroll = () => {
  onWindowResize();
  updateScroll();
  forEach(document.getElementsByClassName('lm_header'), (el) => {
    const tabs = first(el.getElementsByClassName('lm_tabs'));
    if (!tabs || !isEmpty(el.getElementsByClassName('scroll-tab-icon'))) {
      return;
    }

    const faRight = getTabArrow('right', tabs);
    const faLeft = getTabArrow('left', tabs);
    el.appendChild(faLeft);
    el.appendChild(faRight);

    let activeTab = getActiveTab(el);
    const updateIcons = () => {
      const maxScrollLeft = tabs.scrollWidth - tabs.clientWidth;
      const { scrollLeft } = tabs;
      if (scrollLeft === 0) {
        faLeft.style.display = 'none';
      } else {
        faLeft.style.display = 'flex';
      }
      if (maxScrollLeft === scrollLeft || maxScrollLeft === 0) {
        faRight.style.display = 'none';
      } else {
        faRight.style.display = 'flex';
      }
      const currentActiveTab = getActiveTab(el);
      if (activeTab !== currentActiveTab) {
        activeTab = currentActiveTab;
        goToActivetab(el, tabs);
      }
    };
    updateIcons();
    // @ts-expect-error ts-migrate(2551) FIXME: Property 'onscroll' does not exist on type 'Elemen... Remove this comment to see the full error message
    tabs.onscroll = updateIcons;
    tabsPanes.push(tabs);
  });
};

export default tabScroll;
