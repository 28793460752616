import { useEffect } from 'react';

export const useOpenLinksInParentWindow = (
  isSingleExerciseMode: boolean,
): void => {
  useEffect(() => {
    if (
      isSingleExerciseMode &&
      document.head.getElementsByTagName('base').length === 0
    ) {
      document.head.insertAdjacentHTML('afterbegin', '<base target="_top" />');
    }
  }, [isSingleExerciseMode]);
};
