import { connect } from 'react-redux';

import ErrorPage from '../components/ErrorPage';
import * as actions from '../redux/actions';
import * as selectors from '../redux/selectors';
import { BOOT_ERRORS } from '../redux/selectors';

const mapStateToProps = (state: any) => ({
  isApplicationBootable: selectors.selectIsApplicationBootable(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  onInit: (isApplicationBootable: any) => {
    if (isApplicationBootable) {
      dispatch(
        actions.bootFailed({
          error: BOOT_ERRORS.UNKNOWN_ROUTE,
          originalError: null,
          preFetchedData: {},
        }),
      );
    }
  },
});

const mergeProps = ({ isApplicationBootable }: any, { onInit }: any) => ({
  onInit: () => onInit(isApplicationBootable),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ErrorPage);
