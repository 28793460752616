import { ISessionOutputSct, SessionOutput } from '@datacamp/multiplexer-client';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
// eslint-disable-next-line no-restricted-imports
import Rx from 'rxjs';

import * as actions from '../redux/actions';

export const outputsForConsole = [
  'code',
  'script-output',
  'output',
  'r-message',
  'r-warning',
  'parse-error',
  'r-error',
  'backend-error',
  'error',
  'result',
  'unlock-console',
  'lock-console',
];

const mapActionsToType = (action: any, type: any) => {
  switch (`${action}_${type}`) {
    case 'success_BulletExercise':
    case 'success_BulletConsoleExercise':
    case 'success_TabExercise':
    case 'success_TabConsoleExercise':
      return actions.epicCompletingSubExercise;
    case 'wrong_BulletConsoleExercise':
    case 'wrong_TabConsoleExercise':
    case 'wrong_BulletExercise':
    case 'wrong_TabExercise':
      return actions.updateFeedbackMessageOfSubEx;
    default: {
      switch (action) {
        case 'success':
          return actions.completeExercise;
        case 'wrong':
          return actions.updateFeedbackMessage;
        default:
          return () => actions.noopAction;
      }
    }
  }
};

const actionsForError = (output: SessionOutput) => [
  actions.reportOutputToHeap(output),
  actions.updateFeedbackMessageWithAssistant({ error: output.payload }),
  actions.updateFeedbackMessageOfSubExWithAssistant({ error: output.payload }),
];

const actionsForR = {
  'figure-resize': (output: SessionOutput) =>
    actions.rerenderPlot({ ...output.payload }),
  iframe: (output: SessionOutput) =>
    actions.newHtml({
      html: output.payload,
      isRelativeToMultiplexer: false,
    }),
  html_link: (output: SessionOutput) =>
    actions.newHtml({
      src: output.payload,
      isRelativeToMultiplexer: get(
        output,
        'payload_details.is_relative_to_multiplexer',
        false,
      ),
    }),
  pdf_link: (output: SessionOutput) => {
    // @ts-expect-error Property 'payload_details' does not exist on type 'SessionOutput'.
    if (isObject(output.payload_details)) {
      return actions.newPdf({
        src: output.payload,
        isRelativeToMultiplexer:
          // @ts-expect-error Property 'payload_details' does not exist on type 'SessionOutput'.
          output.payload_details.is_relative_to_multiplexer,
      });
    }
    return actions.newPdfDeprecated({ src: output.payload });
  },
  help: (output: SessionOutput) =>
    actions.showRDoc({ payload: output.payload }),
  'figure-expand': (output: SessionOutput) =>
    actions.figureExpanded({
      category: 'graphicalTabs',
      tabKey: 'plot',
      index: output.payload.index,
      src: output.payload.url,
    }),
  logs: (output: SessionOutput) => actions.appendConsoleLog(output.payload),
  'r-error': (output: SessionOutput) => actionsForError(output),
  'parse-error': (output: SessionOutput) => actionsForError(output),
  'try-error': (output: SessionOutput) => actionsForError(output),
};

const outputToActions = {
  common: {
    sct: (output: ISessionOutputSct, exerciseType: any) => {
      const submitSettings = {
        correct: output.payload.correct,
        message: output.payload.message,
        // @ts-expect-error Property 'student_code' does not exist on type '{ message: string; correct: boolean; }
        code: output.payload.student_code,
        // @ts-expect-error Property 'xp' does not exist on type '{ message: string; correct: boolean; }'
        xp: output.payload.xp,
      };
      if (!output.payload.correct) {
        const actionsToReturn: any[] = [
          actions.submitToMainApp(submitSettings),
          mapActionsToType('wrong', exerciseType)({ output }),
        ];
        if ('line_start' in output.payload) {
          actionsToReturn.push(actions.updateHighlight({ output }));
        }
        return actionsToReturn;
      }
      return [
        mapActionsToType(
          'success',
          exerciseType,
        )({
          message: output.payload.message,
          show: true,
          completed: output.payload.correct,
          submitSettings,
        }),
      ];
    },
    server: (output: SessionOutput) =>
      actions.newProxy({ payload: output.payload }),
    graph: (output: SessionOutput) => actions.newPlot({ url: output.payload }),
    object_view: (output: SessionOutput) => {
      return actions.updateObjectView(output.payload);
    },
    output: (output: SessionOutput) => actions.reportOutputToHeap(output),
  },
  r: actionsForR,
  revo: actionsForR,
  python: {
    html: (output: SessionOutput) =>
      actions.showBokeh({ html: output.payload }),
    html_link: (output: SessionOutput) =>
      actions.showBokeh({ src: output.payload }),
    error: (output: SessionOutput) => actionsForError(output),
  },
  julia: {
    html: (output: SessionOutput) =>
      actions.newHtml({ html: output.payload, isRelativeToMultiplexer: false }),
    error: (output: SessionOutput) => actionsForError(output),
  },
  java: {
    error: (output: SessionOutput) => actionsForError(output),
  },
  rust: {
    error: (output: SessionOutput) => actionsForError(output),
  },
  sql: {
    query_result: (output: SessionOutput) =>
      actions.updateSqlQueryResult(output.payload),
    query_message: (output: SessionOutput) =>
      actions.updateSqlQueryMessage(output.payload),
    options: (output: SessionOutput) =>
      actions.updateSqlOptions(output.payload),
    table: (output: SessionOutput) => actions.updateSqlTable(output.payload),
    table_removed: (output: SessionOutput) =>
      actions.updateSqlTable({
        ...output.payload,
        columns: ['The table is removed'],
        records: [],
      }),
    table_names: (output: SessionOutput) =>
      actions.updateSqlTableNames(output.payload),
    error: (output: SessionOutput) => [
      actions.updateSqlError({ output }),
      actionsForError(output),
    ],
    'backend-error': (output: SessionOutput) =>
      actions.updateSqlError({ output }),
  },
  shell: {
    server: (output: SessionOutput) =>
      actions.newShellProxy({ payload: output.payload }),
  },
  containers: {
    server: (output: SessionOutput) =>
      actions.newShellProxy({ payload: output.payload }),
  },
};

const outputs = (language: any, output: SessionOutput, exerciseType: any) => {
  const otherActionsMap = {
    ...outputToActions.common,
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ...outputToActions[language],
  };
  const othersActionsFn =
    otherActionsMap[output.type] || (() => actions.noopAction);
  const othersActions = othersActionsFn(output, exerciseType);
  return (isArray(othersActions)
    ? Rx.Observable.of(...othersActions)
    : Rx.Observable.of(othersActions)
  ).filter((a) => a.type !== actions.noopAction.type);
};

export default outputs;
