/** @jsx jsx */
import { Button } from '@datacamp/waffles/button';
import { Text } from '@datacamp/waffles/text';
import { tokens } from '@datacamp/waffles/tokens';
import { jsx } from '@emotion/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector } from '../../interfaces/State';
import { selectSettings } from '../../redux/selectors';
import showOnCondition from '../HighOrderComponents/showOnCondition';

type Props = {
  current?: number;
  onSubmitCode: (...args: any[]) => any;
  sqlOptions: {
    write_access?: boolean;
  };
  total?: number;
};

const SqlTotalRows: React.FC<Props> = ({
  current,
  onSubmitCode,
  sqlOptions: { write_access: writeAccess },
  total,
}) => {
  const { t } = useTranslation();
  const uiTheme = useSelector((state) => selectSettings(state).get('uiTheme'));
  const isDarkMode = uiTheme === 'DARK';

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        zIndex: 10,
        padding: tokens.spacing.small,
        paddingLeft: tokens.spacing.medium,
        backgroundColor: isDarkMode
          ? tokens.colors.navyDark
          : tokens.colors.grey,
      }}
    >
      <Text
        css={{
          color: isDarkMode ? tokens.colors.white : tokens.colors.navy,
          flexGrow: 1,
        }}
      >
        {t('SqlTotalRows.text', { current, total })}
      </Text>
      <div>
        {writeAccess && (
          <Button
            variant="secondary"
            onClick={() => onSubmitCode({ command: 'reset' })}
            inverted={isDarkMode}
          >
            {t('SqlTotalRows.button')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default showOnCondition(() => true)(SqlTotalRows);
