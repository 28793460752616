import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import request from 'superagent';

import config from '../config';

// TODO remove this code to fetch external ids in a more generic way
const spreadsheetExerciseTypes = ['NormalExercise', 'MultipleChoiceExercise'];

export type Exercise = {
  exercise_image?: string;
  id: number;
  number: number;
  programming_language?: string;
  runtime_config?: string;
  type: string;
};

const fetchExternalExerciseIds = async (
  course: { id: number; programming_language: string },
  chapterId: number,
  exercises: Exercise[],
) => {
  // see if it's a spreadsheet and (normal exercises or multiple choice exercise)
  const isSpreadsheetsCourse = course.programming_language === 'spreadsheets';

  if (isSpreadsheetsCourse) {
    const { body: externalExercises = [] } = await request.get(
      `${config.urls.googleSheets}/api/v1/external/courses/${course.id}/chapters/${chapterId}/exercises`,
    );

    const externalExercisesArray = sortBy(externalExercises, 'number');
    return exercises.map((exercise: Exercise, index: number) => {
      const isSpreadsheetExerciseType = spreadsheetExerciseTypes.includes(
        exercise.type,
      );
      return {
        ...exercise,
        externalId: isSpreadsheetExerciseType
          ? get(externalExercisesArray, `[${index}].id`, exercise.id)
          : exercise.id,
        type: isSpreadsheetExerciseType
          ? 'SpreadsheetsExercise'
          : exercise.type,
      };
    });
  }

  return exercises.map((exercise: Exercise) => ({
    ...exercise,
    externalId: exercise.id,
  }));
};

export default fetchExternalExerciseIds;
