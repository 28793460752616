import { useToast } from '@datacamp/waffles/toast';
import React, { useEffect } from 'react';

import { useSelector } from '../interfaces/State';
import { selectIsUserStatusError } from '../redux/selectors';

const ToastOnUserStatusError: React.FC = () => {
  const isUserStatusError = useSelector(selectIsUserStatusError);
  const { toast } = useToast();

  useEffect(() => {
    if (isUserStatusError) {
      toast({
        title: 'Failed to authenticate',
        variant: 'error',
        disableAutoHide: true,
      });
    }
  }, [isUserStatusError, toast]);
  return null;
};

export default ToastOnUserStatusError;
